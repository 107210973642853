.container {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    margin-right: 20px;
}

.top {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.bullet {
    border: 2px solid var(--greyFont);
    background-color: var(--light-grey);
    height: 12px;
    width: 12px;
    border-radius: 20px;
    margin-right: 10px;
}
.deleteBtn {
    font-size: 26px;
    height: 26px;
    color: '#787878';
    cursor: pointer;
    margin-left: auto;
}

.stepSelectorContainer {
    margin-top: 10px;
}
.cbrIntegration {
    height: 30px;
    display: flex;
    margin-top: auto;
    align-items: center;
    margin-bottom: auto;
    flex-direction: row;
    font-size: 16px;
}

.icon {
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sincoIcon {
    margin-right: 7px;
    height: 28px;
    width: 28px;
    color: var(--secondary);
}
