.descriptionLabel {
    color: var(--accent);
    font-size: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
    font-weight: 600;
}

.addIntegrationsBtn {
    width: 150px;
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--accent);
    margin-left: 15px;
    cursor: pointer;
}

.addIconContainer {
    font-size: 28px;
    height: 28px;
}

.addIntegrationsLbl {
    font-size: 16px;
    color: var(--accent);
}

.menuContainer {
    width: 100%;
    height: 32px;
    padding: 5px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
}
