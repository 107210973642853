.container {
    margin: 0px 16px 0px 10px;
    display: flex;
    z-index: 2;
    justify-content: flex-end;
}
.mobileContainer {
    margin: 0px 10px 0px 10px;
    display: flex;
    z-index: 2;
    justify-content: center;
}

.selectContainer {
    margin-left: 4px;
    margin-right: 4px;
    margin-top: 4px;
    margin-bottom: 4px;
    width: 210px;
}

@media screen and (max-width: 461px) {
    .selectContainer {
        width: 100%;
        max-width: calc(100% - 8px);
    }
}

.classMenu:hover {
    border: 1px solid var(--greyFont);
}

.filtrosLbl {
    margin-left: 10px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}

.dropDownContainer {
    width: 18px;
    height: 20px;
    margin: auto 5px auto auto;
}

.dropDown {
    font-size: 30px;
    width: 30px;
    margin-left: -6px;
    margin-top: -5px;
    height: 30px;
    cursor: pointer;
}
