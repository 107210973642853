.top,
.bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 40px;
}
.bottom {
    flex-wrap: wrap;
    margin-left: 60px;
    row-gap: 10px;
    margin-top: 15px;
}

.bullet {
    border: 2px solid var(--greyFont);
    background-color: var(--light-grey);
    height: 10px;
    width: 10px;
    border-radius: 20px;
    margin-right: 10px;
    /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); */
}

.deleteBtn {
    font-size: 21px;
    height: 20px;
    width: 20px;
    margin-right: 8px;
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--greyFont);
    cursor: pointer;
}

.deleteBtn:hover {
    color: var(--dark-grey);
}

.filterTypeSelector {
    width: 320px;
    margin-right: 20px;
}
.entityValueInput {
    width: 320px;
    margin-top: 10px;
    margin-left: 60px;
}
.requiredContainer {
    margin-left: 75px;
    margin-top: 10px;
    font-size: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.toggleContainer {
    margin-top: 2px;
    margin-left: 5px;
}
